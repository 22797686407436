








































































































import { DragItemInfo } from '@/models/core/DragItemInfo';
import { ClassSubjectDto } from '@/models/planning/ClassSubjectDto';
import { TeacherDto } from '@/models/planning/TeacherDto';
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex';
import ClassSubjectTeacherEdit from "../../../editpanels/ClassSubjectTeacherEdit.vue";

export default Vue.extend({
  components: { ClassSubjectTeacherEdit },
  name: "ClassSubject",
  props: {
    classSubject: {
      required: true,
      type: Object
    },
    teacherFilter: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    dragItemInfo: null
  }),
  mounted() {
    this.registerDropListener({
      source: "teacher",
      target: "classsubject",
      action: (source: DragItemInfo, target: DragItemInfo) => {
        this.initTeacherOnSubjectAllocation({teacher:source.value, classSubject:target.value});
      }
    });

    this.dragItemInfo = this.getClassSubjectDragInfo(this.classSubject);
  },
  computed: {
    ...mapGetters('dragAndDropStore', ["getClassSubjectDragInfo", 'canDrop', 'dragOver', 'dragTarget', 'dragTargetHover']),
    
    ...mapGetters("distributionStore", ["allocationsForClassSubject", "hoursForClassSubject"]),
    
    teachers() {
      return this.filteredTeachers;
    },
    ...mapGetters("teachers", ["filteredTeachers"]),
  },
  methods: {
    ...mapActions('dragAndDropStore', ['dragStart', 'dragEnd', 'drop', 'dragEnter', 'dragLeave']),
    ...mapActions("dragAndDropStore", ["registerDropListener"]),
    
    getPlannedStatus(subject: ClassSubjectDto): "danger" | "success" {
      const planned = this.getTotalHours(subject)
      const actual = this.getClassSubjectMax(subject)

      const status = planned <= actual ? "success" : "danger";

      return status;
    },
    getClassSubjectMax(classSubject: ClassSubjectDto) {
      return Math.round((classSubject.budgetPlanningHours ?? 0) * (classSubject.subjectPlanningHoursFactor ?? 0));
    },
    getClassSubjectActualHours(classSubject: ClassSubjectDto) {
      return Math.round((classSubject.numberOfStudentsReported ?? 0) * (classSubject.remoteStudentsToHoursFactor ?? 0));
    },
    getTotalHours(subject: ClassSubjectDto) {
      return this.hoursForClassSubject(subject);
    },
    async onClassSubjectBlur(classSubject: ClassSubjectDto) {
      await this.updateClassSubject(classSubject);
    },
    ...mapActions("classSubjectStore", ["updateClassSubject"]),
    ...mapActions("distributionStore", ["initTeacherOnSubjectAllocation"]),
    teacherSelected(classSubject: ClassSubjectDto, teacher: TeacherDto) {
      this.initTeacherOnSubjectAllocation({teacher, classSubject});
    },
  }
})
