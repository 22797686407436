




































































































import Vue from "vue"
import { mapActions, mapGetters } from "vuex"
import { ClassSubjectCriteriaModel } from "@/models/filterpanel/ClassSubjectCriteriaModel";
import { defaultProjectCriteriaModel } from "@/models/filterpanel/ProjectCriteriaModel";
import { TeacherCriteriaModel } from "@/models/filterpanel/TeacherCriteriaModel";
import { ClassSubjectDto } from "@/models/planning/ClassSubjectDto";
import { TeacherDto } from "@/models/planning/TeacherDto";
import Teacher from "./list-items/Teacher.vue";
import { formatMultiselectEmit } from "@/models/core/FilterPanel";
import { SchoolSubjectDto } from "@/models/planning/SchoolSubjectDto";
import { EducationalDirectionDto } from "@/models/planning/EducationalDirectionDto";

export default Vue.extend({
  name: "RemoteEducationTeachers",
  async mounted() {
    // const remoteTeachers = this.teachers.filter(teacher => teacher.teacherTerms.some(term => term.relDepartment === 7));
  },  
  components: {
    Teacher
  },
  props: ["teacherFilter"],
  data: () => ({
    projects: defaultProjectCriteriaModel,
    classSubjectsFilter: {
      departments: [7]
    } as ClassSubjectCriteriaModel,
    selectedEducationalDirections: null,
    selectedSchoolSubjects: null,
  }),
  computed: {
    ...mapGetters(['globalSchoolTermSelection']),
    ...mapGetters("distributionStore", {allocationsForTeacher: "allocationsForTeacher", hoursForTeacher: "hoursForTeacher", distributionsLoading: "isLoading"}),
    ...mapGetters("teachers", {filteredTeachers: "filteredTeachers", teachersUnfiltered: "teachers"}),
    teachers() {
      return this.filteredTeachers;
    },
    averageAllocatedHoursForView() {
      const viewCount = this.teachers.length;
      const viewTotal = this.teachers
        .map((q:TeacherDto) => this.allocationsForTeacher(q).reduce((p,c)  => p + c.hours, 0))
        .reduce((previous:number, current:number) => previous + current, 0);
      const result = Math.round(viewTotal / viewCount);
      return isNaN(result) ? 0 : result;
    },
    // This method is probably wrong, but since it tries to approximate the described algorithm, I keep it until we have confirmation that the other algorithm is correct.
    // percentageAllocatedHoursForView() {
    //   const currentEmploymentFactor = (terms:TeacherTermDto[]) => {
    //     const term = terms.find(q => q.relSchoolTerm == this.globalSchoolTermSelection);
    //     if (term) {
    //       return term.employmentFactor;
    //     }
    //     return 0;
    //   }

    //   const viewTotal = this.teachers
    //     .map((q:TeacherDto) => this.allocationsForTeacher(q).reduce((p,c)  => p + c.hours, 0))
    //     .reduce((previous:number, current:number) => previous + current, 0);

    //   const viewNorm = this.teachers
    //     .reduce((previous:number, current:TeacherDto) => previous + (100 / currentEmploymentFactor(current.teacherTerms) * this.hoursForTeacher(current) ), 0);
      
    //   const subjectNorm = this.classSubjects
    //     .reduce((previous:number, current:ClassSubjectDto) => previous + (current.numberOfStudentsExpected * current.remoteStudentsToHoursFactor), 0);

    //   return (viewTotal / viewNorm) * subjectNorm;
    // },
    percentageAllocatedHoursForView() {
      const viewTeacherHoursTotal = this.teachers.reduce((p:number,teacher:TeacherDto) => p + this.hoursForTeacher(teacher), 0);
      const viewTeacherHoursForPlanningTotal = this.teachers.reduce((p:number, teacher:TeacherDto) => p + this.teacherHoursForPlanning(teacher.id), 0);

      const result = (viewTeacherHoursTotal / viewTeacherHoursForPlanningTotal) * 100;
      return isNaN(result) ? 0 : result;
    },
    educationalDirectionsForTeacherSubject() {
      return this.educationDirections('teachersubject');
    },
    classSubjects() {
      return this.filteredClassSubjects;
    },
    ...mapGetters("departments", ["departments"]),
    ...mapGetters("departments", {departments: "departments", departmentsLoading: "isLoading"}),
    ...mapGetters('educationDirectionsStore', {educationDirections: 'educationDirections', educationDirectionsLoading: 'isLoading'} ),
    ...mapGetters("schoolSubjectsStore", {schoolSubjects: "schoolSubjects", schoolSubjectsLoading: "isLoading"}),
    ...mapGetters("teacherTermsStore", {teacherHoursForPlanning: "teacherHoursForPlanning", teacherTermsLoading: "isLoading"}),
    ...mapGetters("classSubjectStore", ["filteredClassSubjects"]),
    isLoading() {
      return this.departmentsLoading ||
             this.educationDirectionsLoading ||
             this.schoolSubjectsLoading ||
             this.distributionsLoading ||
             this.teacherTermsLoading;
    }
  },
  methods: {    
    ...mapActions("classSubjectStudentTypeStore", {
      loadFilteredClassSubjects: "loadFiltered", 
      updateClassSubject: "update"
    }),
    async onClassSubjectBlur(classSubject: ClassSubjectDto) {
      await this.updateClassSubject(classSubject);
    },
    filterTeachers() {
      const filter = {
        departments: [7],
        educationalDirections: formatMultiselectEmit(this.selectedEducationalDirections, (q: EducationalDirectionDto) => q.id),
        schoolSubjects: formatMultiselectEmit(this.selectedSchoolSubjects, (q: SchoolSubjectDto) => q.id)
      } as TeacherCriteriaModel;

      this.$emit("filter-change", filter);
    },
  },

})
