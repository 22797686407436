
// Return selection as an array of ids. Anything else returns undefined.
const formatMultiselectEmit = (selected, selectFunc) => {
  if (selected) {
    if (Array.isArray(selected) && selected.length > 0) {
      return selected.map(q => selectFunc(q));
    }
  }
  return undefined;
}

// Return selection as a single value. Anything else returns undefined.
const formatSingleselectEmit = (selected, selectFunc) => {
  if (selected) {
    const selectedValue = selectFunc(selected);
    if (selectedValue !== undefined) {
      return selectedValue;
    }
  }
  return undefined;
}

export { formatMultiselectEmit, formatSingleselectEmit }
