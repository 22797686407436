












































































import Vue from "vue"
import { mapGetters } from "vuex"
import { ClassSubjectDto } from "@/models/planning/ClassSubjectDto";
import ClassSubject from "./list-items/ClassSubject.vue";
import { SchoolSubjectDto } from "@/models/planning/SchoolSubjectDto";
import { formatMultiselectEmit } from "@/models/core/FilterPanel";
import { ClassSubjectCriteriaModel } from "@/models/filterpanel/ClassSubjectCriteriaModel";

export default Vue.extend({
  components: { ClassSubject },
  name: "RemoteEducationClassSubjects",
  async mounted() {
    // const remoteTeachers = this.teachers.filter(teacher => teacher.teacherTerms.some(term => term.relDepartment === 7));
  },  
  data: () => ({
    filter: {
      departments: [7]
    },
    selectedSchoolSubjects: []
  }),
  props: ["teacherFilter"],
  computed: {
    classSubjectsGrouped() {
      if (!this.numberPerStudentTypeReporteds)
        return [];

      type keyValuePair<TKey, TValue> = {
        key: TKey,
        value: TValue
      };

      const result = [] as keyValuePair<string, ClassSubjectDto[]>[];

      for (const classSubject of (this.filteredClassSubjects as ClassSubjectDto[])) {        
        const key = classSubject.relSchoolSubjectNavigation.title;

        const existing = result.find(kv => kv.key === key);

        if (existing) {
          existing.value.push(classSubject)
        } else {
          result.push({
            key,
            value: [classSubject]
          })
        }
      }

      return result;
    },
    ...mapGetters("schoolSubjectsStore", {schoolSubjects: "schoolSubjects", schoolSubjectsLoading: "isLoading"}),
    ...mapGetters("classSubjectStudentTypeStore", {numberPerStudentTypeReporteds: "numberPerStudentTypeReporteds"}),
    ...mapGetters("classSubjectStore", ["filteredClassSubjects"]),
    isLoading() {
      return this.schoolSubjectsLoading;
    }
  },
  methods: {
    getClassSubjectMax(classSubject: ClassSubjectDto) {
      return Math.round((classSubject.budgetPlanningHours ?? 0) * (classSubject.subjectPlanningHoursFactor ?? 0));
    },
    setFilter() {
      this.filter = {
        departments: [7],
        schoolSubjects: formatMultiselectEmit(this.selectedSchoolSubjects, (q: SchoolSubjectDto) => q.id)
      } as ClassSubjectCriteriaModel;
      this.$emit("filter-change", this.filter);
    },

  },

})
