











import Vue from "vue"
import Teachers from "./RemoteEducation/Teachers.vue";
import ClassSubjects from "./RemoteEducation/ClassSubjects.vue";
import { TeacherCriteriaModel } from "@/models/filterpanel/TeacherCriteriaModel";
import { mapActions } from "vuex";
import { ClassSubjectCriteriaModel } from "@/models/filterpanel/ClassSubjectCriteriaModel";

export default Vue.extend({
  name: "RemoteEducation",
  components: {
    Teachers,
    ClassSubjects,
  },
  data: () => ({
    classSubjectsFilter: {
      departments: [7], // Only select remote education
    },
    teachersFilter: {
      departments: [7], // Only select remote education
      educationalDirections: null,
      schoolSubjects: null,
    } as TeacherCriteriaModel
  }),
  mounted() {
    this.setClassSubjectsFilter(this.classSubjectsFilter);
    this.setTeachersFilter(this.teachersFilter);
  },
  methods: {
    ...mapActions("classSubjectStore", ["filterClassSubjects"]),
    ...mapActions("teachers", ["filterTeachers"]),
    setClassSubjectsFilter(newFilter: ClassSubjectCriteriaModel) {
      this.classSubjectsFilter = newFilter;
      this.filterClassSubjects(newFilter);
    },
    setTeachersFilter(newFilter: TeacherCriteriaModel) {
      this.teachersFilter = newFilter;
      this.filterTeachers(newFilter);
    }
  }
})
