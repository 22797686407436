





























import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { TeacherOnSubjectAllocationDto } from '@/models/planning/TeacherOnSubjectAllocationDto';
import { TeacherOnProjectAllocationDto } from '@/models/planning/TeacherOnProjectAllocationDto';
import APISTATE from '@/enums/APISTATE';
import { TeacherDto } from '@/models/planning/TeacherDto';
import { TeacherTermDto } from '@/models/planning/TeacherTermDto';

export default Vue.extend({
  name: 'teacher-edit',
  props: {
    allocation: {
      type: Object as () => TeacherOnSubjectAllocationDto|TeacherOnProjectAllocationDto,
    }
  },
  data: () => ({
    hours: 0 as number,
    percentage: 0,
    state: '0',
    updating: false,
  }),
  mounted() {
    this.updateInputFields();
  },
  methods: {
    ...mapActions('distributionStore', ['updateTeacherOnSubjectAllocation', 'updateTeacherOnProjectAllocation']),
    updateInputFields() {
      this.percentage = (this.teacherTermHours === 0) ? 0 : Math.round((this.allocation.hours / this.teacherTermHours * 100) * 100) / 100;
      this.hours = this.allocation.hours;
    },
    async percentageUpdated() {
      this.hours = Math.round((this.teacherTermHours * this.percentage / 100) * 100) / 100;
      await this.updateAllocation();
    },
    async hoursUpdated() {
      this.percentage = (this.teacherTermHours === 0) ? 0 : Math.round((this.hours / this.teacherTermHours * 100) * 100) / 100;
      await this.updateAllocation();
    },
    async updateAllocation() {
      if (this.updating) return;
      try {
        this.updating = true;
        if (this.isClassSubject) {
          const allocation = {
            relTeacher: this.allocation.relTeacher,
            relClassSubject: this.allocation.relClassSubject,
            state: this.state,
            hours: this.inputHours
          } as TeacherOnSubjectAllocationDto;
          await this.updateTeacherOnSubjectAllocation(allocation);
        }
        else if (this.isProject) {
          const allocation = {
            relTeacher: this.allocation.relTeacher,
            relProject: this.allocation.relProject,
            state: this.state,
            hours: this.inputHours
          } as TeacherOnProjectAllocationDto;
          await this.updateTeacherOnProjectAllocation(allocation);
        }
      }
      catch (error) {
        this.$buefy.toast.open({
          message: 'Fejl ved opdatering af allokering.',
          type: 'is-danger'
        });
      }
      finally {
        this.updating = false;
      }
    }
  },
  computed: {
    ...mapGetters('distributionStore', ['distributionApiState']),
    ...mapGetters('teachers', ['getTeacher']),
    ...mapGetters('teacherTermsStore', ['teacherTermsForSchoolTerm']),
    ...mapGetters('classSubjectStore',  ['classSubjectMergedClasses']),
    loading() {
      return this.distributionApiState === APISTATE.LOADING;
    },
    isClassSubject() {
      return Object.prototype.hasOwnProperty.call(this.allocation, "relClassSubjectNavigation");
    },
    isProject() {
      return Object.prototype.hasOwnProperty.call(this.allocation, "relProjectNavigation");
    },
    classSubjectId() {
      if (this.isClassSubject) {
        const merged = this.classSubjectMergedClasses(this.allocation.relClassSubject);
        if (Array.isArray(merged) && merged.length > 0) {
          return `${this.allocation.relClassSubjectNavigation.classId} (${merged.map(q => q.classId).join(',')})`;
        }
        return `${this.allocation.relClassSubjectNavigation.classId}`;
      }
      else if (this.isProject) {
        return `${this.allocation.relProjectNavigation.projectId}`;
      }
      return '---';
    },
    teacherTermHours() {
      const teacher = this.getTeacher(this.allocation.relTeacher) as TeacherDto;
      if (teacher) {
        const terms = this.teacherTermsForSchoolTerm(teacher.id) as TeacherTermDto[];
        if (terms.length === 1 && terms[0].relSchoolTermNavigation) {
          const teachingFactor = terms[0].teachingFactor;
          const teachingNormHoursHF = terms[0].relSchoolTermNavigation.teacingNormHoursHf

          return Math.round((teachingNormHoursHF * teachingFactor / 100) * 100) / 100;
        }
      }
      return 0;
    },
    inputHours() {
      if (this.hours) {
        return parseFloat(this.hours);
      }
      return 0;
    },
    inputPercentage() {
      if (this.percentage) {
        return parseInt(this.percentage, 10);
      }
      return 0;
    }

  },
  watch: {
    'allocation': {
      deep: true,
      handler() {
        this.updateInputFields();
      }
    }
  }
})
