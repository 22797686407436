





























































































import { DragItemInfo } from '@/models/core/DragItemInfo';
import { ClassSubjectDto } from '@/models/planning/ClassSubjectDto';
import { ProjectsDto } from '@/models/planning/ProjectsDto';
import { TeacherDto } from '@/models/planning/TeacherDto';
import { TeacherOnProjectAllocationDto } from '@/models/planning/TeacherOnProjectAllocationDto';
import { TeacherOnSubjectAllocationDto } from '@/models/planning/TeacherOnSubjectAllocationDto';
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex';
import TeacherEdit from "../../../editpanels/TeacherEdit.vue";

export default Vue.extend({
  components: { TeacherEdit },
  name: "Teacher",
  data: () => ({
    dragItemInfo: null,
  }),
  props: {
    teacher: {
      required: true,
      type: Object
    },
    
    classSubjectsFilter: {
      required: true,
      type: Object
    },
  },
  mounted() {
    this.registerDropListener({
      source: "classsubject",
      target: "teacher",
      action: (source: DragItemInfo, target: DragItemInfo) => {
        this.initTeacherOnSubjectAllocation({teacher:target.value, classSubject:source.value});
      }
    });

    this.dragItemInfo = this.getTeacherDragInfo(this.teacher);
  },
  computed: {
    ...mapGetters('dragAndDropStore', ["getTeacherDragInfo", 'canDrop', 'dragOver', 'dragTarget', 'dragTargetHover']),
    ...mapGetters("distributionStore", {allocationsForTeacher: "allocationsForTeacher", hoursForTeacher: "hoursForTeacher", distributionsLoading: "isLoading"}),
    ...mapGetters("teacherTermsStore", {teacherHoursForPlanning: "teacherHoursForPlanning"}),
    ...mapGetters("classSubjectStore", ["filteredClassSubjects"]),
    actualHours() {
      const allocations = this.allocationsForTeacher(this.teacher);

      const classSubjectsReported = allocations
        .filter((q:Object) => Object.prototype.hasOwnProperty.call(q, "relClassSubjectNavigation"))
        .map((q: TeacherOnSubjectAllocationDto) => q.relClassSubjectNavigation)
        .reduce((previous:number, current:ClassSubjectDto) => previous + (current.numberOfStudentsReported ?? 0) * (current.remoteStudentsToHoursFactor ?? 0), 0)

      const projectWorkHours = allocations
        .filter((q:any) => Object.prototype.hasOwnProperty.call(q , "relProjectNavigation"))
        .map((q: TeacherOnProjectAllocationDto) => q.relProjectNavigation)
        .reduce((previous:number, current:ProjectsDto) => previous + current.numberOfPlanningHours ?? 0, 0);

      // if (this.teacher.id === '8C8C7413-E9A0-4239-9DA6-936BB1173994'.toLowerCase()) {
      //   console.log(projectWorkHours, classSubjectsReported);
      // }
      return classSubjectsReported + projectWorkHours;
    }
  },
  methods: {
    ...mapActions('dragAndDropStore', ["registerDropListener", 'dragStart', 'dragEnd', 'drop', 'dragEnter', 'dragLeave']),
    ...mapActions("distributionStore", ["initTeacherOnSubjectAllocation", "initTeacherOnProjectAllocation"]),
    
    classSubjectSelected(classSubject: ClassSubjectDto, teacher: TeacherDto) {
      this.initTeacherOnSubjectAllocation({teacher, classSubject});
    },
    getPlannedStatus(teacher: TeacherDto): "danger" | "success" {
      const planned = this.hoursForTeacher(teacher);
      const actual = this.actualHours;

      const status = planned <= actual ? "success" : "danger";

      return status;
    },
  }
})
